import type { ReportNodeSchemaLatest } from '~/adapter/indexedDB/types'
import type { SignatureAnswer } from '~/domain/report/model/report/node/questionNode/signatureQuestionNode/signatureAnswer'
import type { SignatureQuestionNode } from '~/domain/report/model/report/node/questionNode/signatureQuestionNode/signatureQuestionNode'
import { convertReportImage } from '../image/convertImage'
import { convertMemo } from './convertMemo'
import { convertToHide } from './convertToHide'

type SignatureQuestionNodeSchema = Extract<
  ReportNodeSchemaLatest['data']['nodes'][0],
  { type: 'question'; questionType: 'signature' }
>
type SignatureAnswerSchema = SignatureQuestionNodeSchema['answer']

// TODO: convertSignatureQuestionNode に rename
// labels: ph.3
export const convertSignature = async (
  node: SignatureQuestionNode,
): Promise<SignatureQuestionNodeSchema> => {
  const answer = await convertAnswer(node.answer)
  return {
    ...node,
    answer,
    memo: await convertMemo(node.memo),
    hide: convertToHide(node),
  }
}

const convertAnswer = async (
  answer: SignatureAnswer | undefined,
): Promise<SignatureAnswerSchema | undefined> => {
  if (answer === undefined) {
    return undefined
  }

  return {
    ...answer,
    image: await convertReportImage(answer.image),
  }
}
