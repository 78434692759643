import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Box, ListItem, Switch, Typography, type SxProps } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useOfflineModeStore } from '~/stores/offlineMode/offlineMode'
import { colorText, colorTextTertiary } from '~/styles/textColors'
import type { Theme } from '@emotion/react'

const styles = {
  item: {
    minHeight: '48px',
  },
  icon: {
    padding: '3px 12px 0px 0px',
  },
  text: {
    fontSize: '16px',
    color: colorText,
  },
  listItemContents: {
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
} satisfies Record<string, SxProps<Theme>>

type Props = {
  handleOnClick?: () => void
}

export function HamburgerMenuOfflineItem({ handleOnClick }: Props) {
  const isOfflineMode = useOfflineModeStore(state => state.offlineMode)

  return (
    <ListItem disablePadding sx={styles.item}>
      <Box sx={styles.listItemContents}>
        <ListItemIcon sx={styles.icon}>
          <FileDownloadIcon sx={{ color: colorTextTertiary }} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2" component="div" sx={styles.text}>
            オフラインモード
          </Typography>
        </ListItemText>
        <Switch
          aria-label="オフラインモード切り替え"
          checked={isOfflineMode}
          onClick={handleOnClick}
        />
      </Box>
    </ListItem>
  )
}
