import type { ReactNode } from 'react'

import { Navigate } from 'react-router-dom'
import { selectHasAnyUserRole, useSessionStore } from '~/stores/session/session'
import { routes } from './paths'
import type { AppRoleEnum } from '@ulysses-inc/harami_api_client'

type Props = {
  rolesRequired: readonly AppRoleEnum[]
  children: ReactNode
}

/**
 * ログインユーザーが指定されたいずれかの role をもっている場合のみ遷移可能なルートをラップするためのコンポーネント
 *
 * @param rolesRequired
 * @returns
 */
export function RolesRequiredRoute({ rolesRequired, children }: Props) {
  const hasAnyRole = useSessionStore(selectHasAnyUserRole(rolesRequired))
  if (!hasAnyRole) {
    return <Navigate to={routes.login.buildPath({})} replace />
  }
  return children
}
