import type { PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'
import { useFeatureTemplateHideSetting } from '~/domain/featureFlags/hooks/userFeatureTemplateHideSetting'
import { rolesLeaderOrHigher } from '~/domain/user/userRole'
import { OnlyOnlineRoute } from './OnlyOnlineRoute'
import { ProtectedRoute } from './ProtectedRoute'
import { RolesRequiredRoute } from './RolesRequiredRoute'
import { routes } from './paths'

export function TemplateHideSettingRoute({ children }: PropsWithChildren) {
  const canUseTemplateHideSetting = useFeatureTemplateHideSetting()
  if (!canUseTemplateHideSetting) {
    return <Navigate to={routes.login.buildPath({})} replace />
  }
  return (
    <ProtectedRoute>
      <RolesRequiredRoute rolesRequired={rolesLeaderOrHigher}>
        <OnlyOnlineRoute>{children}</OnlyOnlineRoute>
      </RolesRequiredRoute>
    </ProtectedRoute>
  )
}
