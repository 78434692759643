/* eslint-disable react-refresh/only-export-components */
import { createContext, useContext } from 'react'
import type { LobsterServiceContract } from './contract'

const lobsterServiceContext = createContext<LobsterServiceContract | null>(null)

export const useLobsterService = () => {
  const lobsterService = useContext(lobsterServiceContext)
  if (!lobsterService) {
    throw new Error('LobsterService not found')
  }
  return lobsterService
}

export const LobsterServiceProvider = lobsterServiceContext.Provider

export const createLobsterService = (): LobsterServiceContract => {
  return {
    renderLabelInspectionList: () => <div>LabelInspectionList</div>,
  }
}
