import { useMemo } from 'react'
import { FeatureNameEnum } from '@ulysses-inc/harami_api_client'
import { appEnv } from '~/config'
import { useFeature } from './useFeatureFlags'

export const useFeatureTemplateHideSetting = () => {
  const { canUse } = useFeature(
    FeatureNameEnum.HARAMI_REPORT_TEMPLATE_HIDE_SETTING,
  )

  return useMemo(() => {
    return appEnv.isLocal || canUse === 'yes'
  }, [canUse])
}
