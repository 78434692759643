import { useQuery } from '@tanstack/react-query'
import { FeatureFlagsApi } from '@ulysses-inc/harami_api_client'
import { useAuthApi } from '~/adapter/api/useAuthApi'
import { useGetDB } from '~/hooks/db/useGetDB'
import { getQueryKey } from '~/lib/tanstackQuery/queryKey'
import { useOfflineModeStore } from '~/stores/offlineMode/offlineMode'
import {
  useUserPlaceNodeStore,
  selectSelectedPlaceNodeUUID,
} from '~/stores/place/place'
import { useSessionStore } from '~/stores/session/session'
import type { FeatureNameEnum } from '@ulysses-inc/harami_api_client'

/*
 * ユーザーには以下のような認識で使って貰えばOK
 *
 * 以下のタイミングで、新機能が表示（非表示）になる
 *  - ブラウザをリロードした時
 *  - ブラウザを開いたままにした場合でも、30 分ほど経過した後で、画面操作した時
 *
 * Feature Flags を OFF にした瞬間に機能を塞ぐ、ということはできないが、
 * まずは、以下のような点を重視して、決めで staleTime を定義しておく。
 * もちろんやってみた結果、再検討の余地は大いにある。
 *
 * - Feature Flags 周りの機構に負荷をかけすぎないこと
 * - ユーザーが不思議に思う動作が発生する確率を下げること
 *   - staleTime が短い場合、操作をしている最中に機能の表示/非表示が切り替わったという現象に遭遇する確率が高くなる
 *
 */
const STALE_TIME_MILLIS = 30 * 60 * 1000 // 30 分

const useEnabledFeaturesQuery = () => {
  const isOffline = useOfflineModeStore(state => state.offlineMode)
  const { getApi } = useAuthApi()
  const { getDBWithThrow } = useGetDB()

  const selectedPlaceNodeUuid = useUserPlaceNodeStore(
    selectSelectedPlaceNodeUUID,
  )
  // ユーザーデータが localStorage に反映される前にマウントされるケースも想定し、エラーを吐く selector は使用しない
  const userUuid = useSessionStore(state => state.user?.uuid)
  const companyId = useSessionStore(state => state.user?.company.id)

  return useQuery({
    queryKey: getQueryKey(
      '/enabledFeatures',
      'GET',
      {},
      {},
      {
        userUuid,
        placeNodeId: selectedPlaceNodeUuid,
        companyId,
        isOffline,
      },
    ),
    queryFn: async () => {
      if (isOffline) {
        if (!userUuid || !companyId || !selectedPlaceNodeUuid) {
          return {
            features: [],
          }
        }
        const db = await getDBWithThrow()
        const userFeatureFlags = await db.featureFlags
          .where({
            userUuid,
            placeNodeId: selectedPlaceNodeUuid,
            companyId,
          })
          .first()

        if (!userFeatureFlags) {
          return {
            features: [],
          }
        }

        return {
          features: userFeatureFlags.enabledFeatures,
        }
      }

      return await getApi(FeatureFlagsApi).getEnabledFeatures()
    },
    select: data =>
      new Set<FeatureNameEnum>(data.features.map(feature => feature.name)),
    gcTime: Infinity,
    staleTime: STALE_TIME_MILLIS,
    // エラーが起きた際でも、共通処理でハンドリングしない
    // true にすると、共通のエラーハンドリングでエラーが拾われ、予期せぬエラー画面に遷移してしまう
    throwOnError: false,
  })
}

type CanUse = 'yes' | 'no' | 'unknown'

/**
 * featureName で表される機能が 利用できるかどうかを判定するためのカスタムフック
 *
 * @param featureName
 * @returns canUse: yes: 機能が利用できる, no: 機能が利用できない(エラーの場合も兼ねる)、unknown: 通信中で判断できない
 */
export const useFeature = (
  featureName: FeatureNameEnum,
): {
  canUse: CanUse
} => {
  const { data, isLoading } = useEnabledFeaturesQuery()

  if (isLoading) {
    return { canUse: 'unknown' }
  }

  if (data && data.has(featureName)) {
    return { canUse: 'yes' }
  }

  return { canUse: 'no' }
}

/**
 * 利用できる機能を一覧で取得したい場合に利用するカスタムフック
 */
export const useFeatureFlagsBulk = (): FeatureNameEnum[] => {
  const { data } = useEnabledFeaturesQuery()

  if (!data) {
    return []
  }

  return [...data]
}
