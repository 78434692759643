import { UnhandledInReportingError } from '../error'
import type { LogicNode } from './logicNode/logicNode'
import type { PageNode } from './pageNode/pageNode'
import type { QuestionNode } from './questionNode/questionNode'
import type { SectionNode } from './sectionNode/sectionNode'

export type NodeId = number

export type NodeType = 'page' | 'question' | 'section' | 'logic'

export type NodeBase<T extends NodeType> = {
  id: NodeId
  type: T
  uuid?: string
  nodes: Array<number>
}
export type Node = PageNode | QuestionNode | SectionNode | LogicNode

type RequireNode = (
  node: Node | undefined,
  message?: string,
) => asserts node is Node
const requireNode: RequireNode = (node, message = '') => {
  if (node === undefined) {
    throw new UnhandledInReportingError('node is undefined.', {
      contextualMessage: message,
    })
  }
}

/**
 * nodeIdをキーにした辞書型
 */
export type NodeDict = Partial<Record<NodeId, Node>>

const traverseDescendantNodes = (
  startNodeId: NodeId,
  allNodes: NodeDict,
  func: (node: Node, nodePath: NodeId[]) => void,
  skipChildren: (node: Node, nodePath: NodeId[]) => boolean = () => false,
  path: NodeId[] = [],
) => {
  const node = allNodes[startNodeId]
  if (!node) {
    return
  }

  const newPath = [...path, startNodeId]
  func(node, newPath)
  if (!skipChildren(node, newPath)) {
    node.nodes.forEach(nodeId => {
      traverseDescendantNodes(nodeId, allNodes, func, skipChildren, newPath)
    })
  }
}

const createNodeIdGenerator = (nodeDict: NodeDict) => {
  let startNodeId: number = 0
  Object.entries(nodeDict).forEach(([, node]) => {
    if (node === undefined) return
    startNodeId = Math.max(startNodeId, node.id)
  })
  let nodeId = startNodeId
  return () => {
    nodeId++
    return nodeId
  }
}

/**
 * nodeIdをキーに、そのnodeIdのノードのパス情報を保持する辞書。
 * パス情報とは、ルートノードから自身のノードまでの配列。
 *
 * 例)
 *       1
 *      / \
 *     2   3
 *    / \
 *   4   5
 *
 * {
 *  5: [1, 2, 5],
 *  4: [1, 2, 4],
 *  3: [1, 3],
 *  2: [1, 2],
 *  1: [1],
 * }
 */
export type NodePathDict = Partial<{
  [nodeId: NodeId]: NodeId[]
}>

const generateNodeIdPaths = (
  rootNodeIds: number[],
  allNodeDict: NodeDict,
): NodePathDict => {
  const resultNodeIdPathDict: NodePathDict = {}

  rootNodeIds.forEach(rootNodeId => {
    traverseDescendantNodes(rootNodeId, allNodeDict, (node, path) => {
      resultNodeIdPathDict[node.id] = path
    })
  })

  return resultNodeIdPathDict
}

/**
 * 親ノードを取得する。親ノードが存在しない場合はエラーを投げる。
 *
 * @param targetNodeId
 * @param nodeIdPaths
 * @param allNodeDict
 * @returns
 */
const getParentNode = (
  targetNodeId: number,
  nodeIdPaths: NodePathDict,
  allNodeDict: NodeDict,
): Node => {
  const path = nodeIdPaths[targetNodeId]
  if (path === undefined) {
    throw new UnhandledInReportingError(`nodeIdPaths is undefined`, {
      targetNodeId,
    })
  }

  if (path.length === 0) {
    throw new UnhandledInReportingError(`nodeIdPaths[targetNodeId] is empty`, {
      targetNodeId,
    })
  }

  // targetNodeがルートノードの場合には親が存在しないためエラーを投げる
  if (path.length === 1) {
    throw new UnhandledInReportingError(
      `node without a parent node is detected.`,
      {
        targetNodeId,
      },
    )
  }

  const parentNodeId = path[path.length - 2]
  const parentNode = allNodeDict[parentNodeId]
  requireNode(parentNode)

  return parentNode
}

/**
 * 対象のノードとそのすべての子孫ノードを辞書から削除する
 * @param deletingNodeId
 * @param sectionNodes
 * @param questionNodes
 * @param logicNodes
 * @returns
 */
const deleteAllChildNodes = (
  deletingNodeId: NodeId,
  nodes: NodeDict,
): NodeDict => {
  const prunedAllNodeDict = {
    ...nodes,
  }
  const deletingNode = prunedAllNodeDict[deletingNodeId]
  requireNode(deletingNode)

  // 対象ノードを削除
  delete prunedAllNodeDict[deletingNodeId]

  // 子孫ノードをすべて削除
  const deleteNodesRecursive = (nodes: NodeId[]) => {
    nodes.forEach(nodeId => {
      delete prunedAllNodeDict[nodeId]
    })
  }
  deleteNodesRecursive(deletingNode.nodes)

  return prunedAllNodeDict
}

const _Node = {
  traverseDescendantNodes,
  createNodeIdGenerator,
  generateNodeIdPaths,
  getParentNode,
  deleteAllChildNodes,
}

export const Node: typeof _Node & {
  requireNode: RequireNode
} = {
  ..._Node,
  requireNode,
}
