import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ja } from 'date-fns/locale/ja'
import { router } from '~/app/routes/router'
import { useAppQueryClient } from '~/lib/tanstackQuery/queryClient'
import { createLobsterService, LobsterServiceProvider } from '~/modules/lobster'
import { theme } from '~/styles/theme'

export function Providers() {
  const { queryClient } = useAppQueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      {!!import.meta.env['VITE_APP_TAN_STACK_DEVTOOLS'] && (
        <ReactQueryDevtools />
      )}
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <LobsterServiceProvider value={createLobsterService()}>
            <RouterProvider router={router} />
          </LobsterServiceProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}
