import { useEffect, useMemo } from 'react'

import { useOfflineModeStore } from '~/stores/offlineMode/offlineMode'
import { useUserPlaceNodeStore } from '~/stores/place/place'
import { selectIsAuthed, useSessionStore } from '~/stores/session/session'
import { useBrowserSessionObservation } from './useBrowserSessionObservation'
import { useClearAllPersistedData } from './useClearAllPersistedData'
import { useQueryLoginUserContext } from './useQueryLoginUserContext'

type Return = {
  /**
   * sync に失敗した場合のエラー情報。isSyncedNow が false の場合は常に null
   */
  error: Error | null
  /**
   * 認証済みかどうか。isSyncedNow が false の場合は、過去にログインした情報が端末に残っているかどうかのみで判断される
   */
  isAuthed: boolean
  /**
   * sync がいま行われたかどうか。オフラインモード時は false
   */
  isSyncedNow: boolean
}

/**
 * 前提として、Rump ログインにおいてログイン画面は Rump 側ドメインで管理されており、そこに遷移するために backend の `/auth/mobileLogin` へ location API で遷移する必要がある。
 * Rump 側でログインに成功すると、リダイレクトで backend で持っているリダイレクト先（現在は `/` トップページ）に戻ってくるが、この時に Cookie に harami_sid という名前でセッション ID がセットされることで認証ができる状態になる。
 * この hooks は、クライアントとしての内部 state のログイン状態を同期させるために、`/loginUser/context` へリクエストを送った結果としてログインユーザー情報が取得できればログイン状態として更新するという役割のもの。
 *
 * オフラインモード時はログイン状態であるかどうかのみを判断する（特段の更新処理や API リクエストを行わない）
 * また、そのログイン状態についても、端末に過去にログインしたことがある情報を持っているかどうかでのみ判断する。
 *
 * @returns
 */
export const useSyncedAuthState = (): Return => {
  const shouldSyncNow = !useOfflineModeStore(state => state.offlineMode)
  const isAuthed = useSessionStore(selectIsAuthed)
  const user = useSessionStore(state => state.user)
  const rumpLogin = useSessionStore(state => state.rumpLogin)
  const newLoginUserPlaceNodeFetched = useUserPlaceNodeStore(
    state => state.newLoginUserPlaceNodeFetched,
  )
  const { clearAllPersistedData } = useClearAllPersistedData()
  const {
    data: loginUser,
    error,
    isLoading,
  } = useQueryLoginUserContext({
    enabled: shouldSyncNow,
  })

  useBrowserSessionObservation(loginUser)

  useEffect(() => {
    if (!shouldSyncNow) return
    if (!loginUser) return

    // NOTE: ログイン中に別のアカウントで重ねてログインする可能性がある。別のアカウントの情報と混ざらないように明示的に localStorage をクリアしておく。
    if (loginUser.userUuid !== user?.uuid) {
      clearAllPersistedData()
    }

    rumpLogin({
      company: loginUser.company,
      name: loginUser.userName,
      uuid: loginUser.userUuid,
      userRole: loginUser.userRole,
    })
    newLoginUserPlaceNodeFetched(loginUser.placeNodes)

    // NOTE: user の用途はログイン時の localStorage クリア処理のみであり、これは loginUser と shouldSyncNow によって制御されるべきなため、depsList から除外している
    // user に依存するとログアウト時の localStorage クリア処理でもこの useEffect が動作してしまい、その場合は loginUser が残っているため rumpLogin が実行されてしまう
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clearAllPersistedData,
    loginUser,
    newLoginUserPlaceNodeFetched,
    rumpLogin,
    shouldSyncNow,
    // user?.uuid,
  ])

  // NOTE: store にログイン情報がセットされて完了とする
  const _isAuthed = useMemo(() => {
    return shouldSyncNow ? !isLoading && isAuthed : isAuthed
  }, [isAuthed, isLoading, shouldSyncNow])

  return {
    error,
    isAuthed: _isAuthed,
    isSyncedNow: shouldSyncNow,
  }
}
