import { createBrowserRouter } from 'react-router-dom'
import { App } from '../App'
import { HomePageRoute } from './HomePageRoute'
import { OnlyOnlineRoute } from './OnlyOnlineRoute'
import { PlaceListPageRoute } from './PlaceListPageRoute'
import { ProtectedRoute } from './ProtectedRoute'
import { TemplateHideSettingRoute } from './TemplateHideSettingRoute'
import { TemplateListPageRoute } from './TemplateListPageRoute'
import { routes } from './paths'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: routes.login.path,
        lazy: async () => {
          const { RumpLogin } = await import('~/pages/RumpLogin/RumpLogin')
          return { element: <RumpLogin /> }
        },
      },
      {
        path: routes.places.path,
        lazy: async () => {
          const { PlacesList } = await import('~/pages/PlacesList/PlacesList')
          return {
            element: (
              <ProtectedRoute>
                <PlaceListPageRoute>
                  <PlacesList />
                </PlaceListPageRoute>
              </ProtectedRoute>
            ),
          }
        },
      },
      {
        path: routes.templates.path,
        lazy: async () => {
          const { TemplatesList } = await import(
            '~/pages/TemplatesList/TemplatesList'
          )
          return {
            element: (
              <ProtectedRoute>
                <TemplateListPageRoute>
                  <TemplatesList />
                </TemplateListPageRoute>
              </ProtectedRoute>
            ),
          }
        },
      },
      {
        path: routes.home.path,
        lazy: async () => {
          const { Home } = await import('~/pages/Home/Home')
          return {
            element: (
              <ProtectedRoute>
                <HomePageRoute>
                  <Home />
                </HomePageRoute>
              </ProtectedRoute>
            ),
          }
        },
      },
      {
        path: routes.reportsNew.path,
        lazy: async () => {
          const { ReportsNew } = await import('~/pages/ReportsNew/ReportsNew')
          return {
            element: (
              <ProtectedRoute>
                <ReportsNew />
              </ProtectedRoute>
            ),
          }
        },
      },
      {
        path: routes.reportsNewFromSchedule.path,
        lazy: async () => {
          const { ReportsNewFromSchedule } = await import(
            '~/pages/ReportsNewFromSchedule/ReportsNewFromSchedule'
          )
          return {
            element: (
              <ProtectedRoute>
                <ReportsNewFromSchedule />
              </ProtectedRoute>
            ),
          }
        },
      },
      {
        path: routes.reportsCompleted.path,
        lazy: async () => {
          const { ReportsCompleted } = await import(
            '~/pages/ReportsCompleted/ReportsCompleted'
          )
          return {
            element: (
              <ProtectedRoute>
                <ReportsCompleted />
              </ProtectedRoute>
            ),
          }
        },
      },
      {
        path: routes.reportsCompletedForceUpdate.path,
        lazy: async () => {
          const { ReportsCompletedForceUpdate } = await import(
            '~/pages/ReportsCompletedForceUpdate/ReportsCompletedForceUpdate'
          )
          return {
            element: (
              <ProtectedRoute>
                <OnlyOnlineRoute>
                  <ReportsCompletedForceUpdate />
                </OnlyOnlineRoute>
              </ProtectedRoute>
            ),
          }
        },
      },
      {
        path: routes.reports.path,
        lazy: async () => {
          const { ReportsList } = await import(
            '~/pages/ReportsList/ReportsList'
          )
          return {
            element: (
              <ProtectedRoute>
                <OnlyOnlineRoute>
                  <ReportsList />
                </OnlyOnlineRoute>
              </ProtectedRoute>
            ),
          }
        },
      },
      {
        path: routes.reportsDetail.path,
        lazy: async () => {
          const { ReportsDetail } = await import(
            '~/pages/ReportDetail/ReportDetail'
          )
          return {
            element: (
              <ProtectedRoute>
                <OnlyOnlineRoute>
                  <ReportsDetail />
                </OnlyOnlineRoute>
              </ProtectedRoute>
            ),
          }
        },
      },
      {
        path: routes.reportsEdit.path,
        lazy: async () => {
          const { ReportsEdit } = await import(
            '~/pages/ReportsEdit/ReportsEdit'
          )
          return {
            element: (
              <ProtectedRoute>
                <OnlyOnlineRoute>
                  <ReportsEdit />
                </OnlyOnlineRoute>
              </ProtectedRoute>
            ),
          }
        },
      },
      {
        path: routes.expiredCalendar.path,
        lazy: async () => {
          const { ExpiredCalendar } = await import(
            '~/pages/ExpiredCalendar/ExpiredCalendar'
          )
          return {
            element: (
              <ProtectedRoute>
                <OnlyOnlineRoute>
                  <ExpiredCalendar />
                </OnlyOnlineRoute>
              </ProtectedRoute>
            ),
          }
        },
      },
      {
        path: routes.reportsOffline.path,
        lazy: async () => {
          const { ReportsOffline } = await import(
            '~/pages/ReportsOffline/ReportsOffline'
          )
          return {
            element: (
              <ProtectedRoute>
                <ReportsOffline />
              </ProtectedRoute>
            ),
          }
        },
      },
      {
        path: routes.reportsOfflineDetail.path,
        lazy: async () => {
          const { ReportsOfflineDetail } = await import(
            '~/pages/ReportsOfflineDetail/ReportsOfflineDetail.tsx'
          )
          return {
            element: (
              <ProtectedRoute>
                <ReportsOfflineDetail />
              </ProtectedRoute>
            ),
          }
        },
      },
      {
        path: routes.reportsOfflineEdit.path,
        lazy: async () => {
          const { ReportsOfflineEdit } = await import(
            '~/pages/ReportsOfflineEdit/ReportsOfflineEdit'
          )
          return {
            element: (
              <ProtectedRoute>
                <ReportsOfflineEdit />
              </ProtectedRoute>
            ),
          }
        },
      },
      {
        path: routes.templateHideSettingList.path,
        lazy: async () => {
          const { TemplateHideSettingList } = await import(
            '~/pages/TemplateHideSettingList/TemplateHideSettingList'
          )
          return {
            element: (
              <TemplateHideSettingRoute>
                <TemplateHideSettingList />
              </TemplateHideSettingRoute>
            ),
          }
        },
      },
      {
        path: routes.templateHideSettingEdit.path,
        lazy: async () => {
          const { TemplateHideSetting } = await import(
            '~/pages/TemplateHideSetting/TemplateHideSetting'
          )
          return {
            element: (
              <TemplateHideSettingRoute>
                <TemplateHideSetting />
              </TemplateHideSettingRoute>
            ),
          }
        },
      },
      {
        path: routes.notFound.path,
        lazy: async () => {
          const { NotFound } = await import('~/pages/NotFound/NotFound')
          return {
            element: <NotFound />,
          }
        },
      },
      // どのルートにも当てはまらない場合は404ページを表示
      {
        path: '*',
        lazy: async () => {
          const { NotFound } = await import('~/pages/NotFound/NotFound')
          return {
            element: <NotFound />,
          }
        },
      },
    ],
  },
])
