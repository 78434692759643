import { ResponseTypeEnum } from '@ulysses-inc/harami_api_client'

import type { NoAnswer } from '~/domain/report/model/report/node/questionNode/noAnswer'
import type {
  DeviateProperty,
  QuestionBase,
  QuestionType,
} from '~/domain/report/model/report/node/questionNode/question'
import type { QuestionNodeBase } from '~/domain/report/model/report/node/questionNode/questionNode'
import { isNullish } from '~/utils/isNullish'
import { ApiToModelError } from '../error'
import { convertDateTimeQuestionNode } from './dateTimeQuestionNodeConverter'
import { convertEmployeeQuestionNode } from './employeeQuestionNodeConverter'
import { convertFormulaQuestionNode } from './formulaQuestionNodeConverter'
import { convertHints } from './hintConverter'
import { convertInformationDocumentQuestionNode } from './informationDocumentQuestionNodeConverter'
import { convertInformationUrlQuestionNode } from './informationUrlQuestionNodeConverter'
import { convertMemo } from './memoConverter'
import { convertMultipleChoiceQuestionNode } from './multipleChoiceQuestionNodeConverter'
import { convertMultipleChoiceSetQuestionNode } from './multipleChoiceSetQuestionNodeConverter'
import { convertNumberQuestionNode } from './numberQuestionNodeConverter'
import { convertResultImageQuestionNode } from './resultImageQuestionNodeConverter'
import { convertSignatureQuestionNode } from './signatureQuestionNodeConverter'
import { convertTextQuestionNode } from './textQuestionNodeConverter'
import type {
  ConversionContext,
  MultipleChoiceSetDict,
  QuestionNodeConverter,
} from './types'
import type {
  ReportAnswer,
  ReportNodeSchema,
} from '@ulysses-inc/harami_api_client'

// TODO: 全ての回答種別を実装したら削除する
// labels: ph.3
const ____notImplementedYet____: QuestionNodeConverter = (
  context: ConversionContext,
): never => {
  const { question } = context
  throw new ApiToModelError('This question type is not implemented yet.', {
    question,
  })
}

export const converterMapping = {
  [ResponseTypeEnum.MULTIPLE_CHOICE]: convertMultipleChoiceQuestionNode,
  [ResponseTypeEnum.NUMBER]: convertNumberQuestionNode,
  [ResponseTypeEnum.SIGNATURE]: convertSignatureQuestionNode,
  [ResponseTypeEnum.TEXT]: convertTextQuestionNode,
  [ResponseTypeEnum.RESULT_IMAGE]: convertResultImageQuestionNode,
  [ResponseTypeEnum.DATETIME]: convertDateTimeQuestionNode,
  [ResponseTypeEnum.INFORMATION]: convertInformationDocumentQuestionNode,
  [ResponseTypeEnum.URL]: convertInformationUrlQuestionNode,
  [ResponseTypeEnum.RESPONSE_SET]: convertMultipleChoiceSetQuestionNode,
  [ResponseTypeEnum.EMPLOYEE]: convertEmployeeQuestionNode,
  [ResponseTypeEnum.FORMULA]: convertFormulaQuestionNode,
  [ResponseTypeEnum.GRID_VARIABLE]: ____notImplementedYet____,
  [ResponseTypeEnum.TIME_MEASUREMENT]: ____notImplementedYet____,
} as const satisfies {
  [key in ResponseTypeEnum]: QuestionNodeConverter
}

export const buildConversionContext = (
  node: ReportNodeSchema,
  wholeContext: {
    pageDict: Partial<Record<number, { nodes: number[] }>>
    nodeDict: Partial<Record<number, ReportNodeSchema>>
    parentPageIdByNodeId: Map<number, number>
    parentNodeIdByNodeId: Map<number, number>
    multipleChoiceSetDict: MultipleChoiceSetDict
  },
): ConversionContext => {
  const question = node.question
  if (question === undefined) {
    throw new ApiToModelError('Question node has no question.', { node })
  }

  const questionNodeBase: Omit<
    QuestionNodeBase<QuestionType>,
    'questionType'
  > = {
    id: node.id,
    uuid: node.uuid,
    nodes: node.nodes,
    type: 'question',
    memo: convertMemo({ ...node.question?.responseAnswer }),
  }
  const questionBase: Omit<QuestionBase<QuestionType>, 'type'> = {
    name: question.name ?? '',
    isRequired: question.isRequired === 1,
    isTimeDisplayed: question.isTimeDisplayed === 1,
    hints: convertHints(question.hints),
    excelConversionTypes: question.excelConversionTypes ?? [],
    isHidden: !!node.hide?.hide,
  }
  const deviateProperty: DeviateProperty = {
    deviate: {
      comment: question.deviateComment ?? '',
      notify: question.deviateNotify,
    },
  }
  const responseAnswer = node.question?.responseAnswer
  const recordedAt = convertRecordedAt(responseAnswer)
  const noAnswer = convertNoAnswer(question.responseAnswer)

  return {
    question,
    wholeContext,
    base: {
      node,
      questionNodeBase,
      questionBase,
      deviateProperty,
      recordedAt,
      noAnswer,
    },
  }
}
const convertNoAnswer = (
  responseAnswer: ReportAnswer | undefined,
): NoAnswer | undefined => {
  if (isNoAnswer(responseAnswer)) {
    return {
      type: 'noAnswer',
      reason: responseAnswer?.noAnswer?.reason ?? '',
      recordedAt: convertRecordedAt(responseAnswer),
    }
  }
  return undefined
}

/**
 * NoAnswerである場合 true
 */
const isNoAnswer = (responseAnswer: ReportAnswer | undefined): boolean => {
  // 理由を未記入の状態で一時保存することもできるため、reason の記入有無ではなく
  // noAnswer フィールドの有無で判定している
  return !isNullish(responseAnswer?.noAnswer)
}

const convertRecordedAt = (responseAnswer: ReportAnswer | undefined) => {
  // デフォルト値付きの選択肢質問において、新規レポート開始時に設定されたデフォルト値の場合、recordedAt が undefined となる。
  // これは、ユーザーが回答したものかどうかを判定するために利用されるため、undefined の状態は維持しなければならない。
  return !isNullish(responseAnswer?.recordedAt)
    ? // API のレスポンスを JSON.parse した場合に、Date 型のフィールドが、文字列で得られることがあるので、念の為型変換をしている
      new Date(responseAnswer.recordedAt)
    : undefined
}
