import { z } from 'zod'
import type { AdditionalMessagePattern } from '~/pages/RumpLogin/RumpLogin'
import { Route, pathParam, stateParam } from './routeConfig'
import type {
  ReportStatusEnum,
  ErrorCodeEnum,
} from '@ulysses-inc/harami_api_client'

export type FromPage = {
  page: 'expiredCalendar'
  focusedDateString: string // 'yyyy-MM-dd'
}

const fromPageZod = z.object({
  page: z.literal('expiredCalendar'),
  focusedDateString: z.string(),
})

export const routes = {
  login: new Route<
    '/login',
    undefined,
    | {
        errorCode?: ErrorCodeEnum
        additionalMessage?: AdditionalMessagePattern
      }
    | undefined,
    undefined
  >('/login', {
    querySchema: z.object({
      additionalMessage: z.literal('offline_saved').optional(),
      // NOTE: z.nativeEnum(ErrorCodeEnum) だと、クエリパラメータが string なのに対して ErrorCodeEnum の value が number でズレており zod の safeParse が失敗する
      // すぐに解決する策が見当たらなかったため、ひとまず number に coerce することで対応した
      errorCode: z.number({ coerce: true }).optional(),
    }),
  }),
  home: new Route<'/', undefined, undefined, undefined>('/', {}),
  places: new Route<'/places', undefined, undefined, undefined>('/places', {}),
  templates: new Route<'/templates', undefined, undefined, undefined>(
    '/templates',
    {},
  ),
  reportsNew: new Route<
    '/reports/new',
    undefined,
    undefined,
    { templateId: number }
  >('/reports/new', {
    stateParamsSchema: z.object({
      templateId: stateParam.number,
    }),
  }),
  reportsNewFromSchedule: new Route<
    '/reports/new/from-schedule',
    undefined,
    undefined,
    {
      scheduleId: number
      reportDate: Date
      templateId: number
      scheduleName: string
      isPreviousDayReportByQRCode?: boolean
      fromPage?: FromPage
    }
  >('/reports/new/from-schedule', {
    stateParamsSchema: z.object({
      templateId: stateParam.number,
      reportDate: stateParam.date,
      scheduleId: stateParam.number,
      scheduleName: stateParam.string,
      isPreviousDayReportByQRCode: stateParam.boolean.optional(),
      fromPage: z.optional(fromPageZod),
    }),
  }),
  reportsDetail: new Route<
    '/reports/:reportUuid',
    { reportUuid: string },
    undefined,
    { fromPage?: FromPage } | undefined
  >('/reports/:reportUuid', {
    pathParamsSchema: z.object({
      reportUuid: pathParam.string,
    }),
    stateParamsSchema: z
      .object({
        fromPage: z.optional(fromPageZod),
      })
      .optional(),
  }),
  reportsEdit: new Route<
    '/reports/:reportUuid/edit',
    { reportUuid: string },
    undefined,
    { fromPage?: FromPage; isPreviousDayReportByQRCode?: boolean } | undefined
  >('/reports/:reportUuid/edit', {
    pathParamsSchema: z.object({
      reportUuid: pathParam.string,
    }),
    stateParamsSchema: z.object({
      fromPage: z.optional(fromPageZod),
      isPreviousDayReportByQRCode: stateParam.boolean.optional(),
    }),
  }),
  reportsCompleted: new Route<
    '/reports/completed',
    undefined,
    { reportUuid?: string },
    { isOfflineReport: boolean; fromPage?: FromPage }
  >('/reports/completed', {
    querySchema: z.object({
      reportUuid: z.string().uuid().optional(),
    }),
    stateParamsSchema: z.object({
      isOfflineReport: stateParam.boolean,
      fromPage: z.optional(fromPageZod),
    }),
  }),

  reportsCompletedForceUpdate: new Route<
    '/reports/completed/force-update',
    undefined,
    { reportUuid?: string },
    { isOfflineReport: boolean; fromPage?: FromPage }
  >('/reports/completed/force-update', {
    querySchema: z.object({
      reportUuid: z.string().uuid().optional(),
    }),
    stateParamsSchema: z.object({
      isOfflineReport: stateParam.boolean,
      fromPage: z.optional(fromPageZod),
    }),
  }),
  reports: new Route<
    '/reports',
    undefined,
    { reportStatus?: ReportStatusEnum },
    undefined
  >('/reports', {
    querySchema: z.object({
      reportStatus: z.number({ coerce: true }).optional(),
    }),
  }),
  expiredCalendar: new Route<
    '/expired-calendar',
    undefined,
    { date?: string },
    undefined
  >('/expired-calendar', {
    querySchema: z.object({
      date: z.string().optional(),
    }),
  }),
  reportsOffline: new Route<
    '/reports/offline',
    undefined,
    undefined,
    undefined
  >('/reports/offline', {}),
  reportsOfflineDetail: new Route<
    '/reports/offline/:offlineReportUuid',
    { offlineReportUuid: string },
    undefined,
    undefined
  >('/reports/offline/:offlineReportUuid', {
    pathParamsSchema: z.object({
      offlineReportUuid: pathParam.string,
    }),
  }),
  reportsOfflineEdit: new Route<
    '/reports/offline/:offlineReportUuid/edit',
    { offlineReportUuid: string },
    undefined,
    { fromPage?: FromPage; isPreviousDayReportByQRCode?: boolean } | undefined
  >('/reports/offline/:offlineReportUuid/edit', {
    pathParamsSchema: z.object({
      offlineReportUuid: pathParam.string,
    }),
    stateParamsSchema: z.object({
      fromPage: z.optional(fromPageZod),
      isPreviousDayReportByQRCode: stateParam.boolean.optional(),
    }),
  }),
  templateHideSettingList: new Route<
    '/templateHideSettingList',
    undefined,
    undefined,
    undefined
  >('/templateHideSettingList', {}),
  templateHideSettingEdit: new Route<
    '/templateHideSettingEdit',
    undefined,
    undefined,
    { templateId: number }
  >('/templateHideSettingEdit', {
    stateParamsSchema: z.object({
      templateId: stateParam.number,
    }),
  }),
  notFound: new Route<'/404', undefined, undefined, undefined>('/404', {}),
} as const
