import type { ReportNodeSchemaLatest } from '~/adapter/indexedDB/types'
import type { QuestionNode } from '~/domain/report/model/report/node/questionNode/questionNode'
import type { ToDefined } from '~/utils/types/defined'

type ReportNodeHideSchema = ToDefined<
  ReportNodeSchemaLatest['data']['nodes'][0]
>['hide']

/**
 * ひな形表示設定の変換用
 *
 * 頻繁なオフラインデータのスキーママイグレーションを避けるため、
 * INE-1896 時点では、オフラインデータのスキーマと、Model のスキーマで、ひな形表示設定のフラグを保持するフィールドが異なっている。
 *
 * - オフライン: node.hide.hide === true | false
 * - Model: node.question.isHidden === true | false
 *
 * オフラインデータ from/to Model の変換を Spread 演算子で行っている場合、
 *
 * 例：
 * ```
 * offlineNode = { ...onlineNode, hide: convertToHide(onlineNode) }
 * ```
 *
 * - indexedDB に格納されているデータ
 * - indexedDB から取得して Model に変換したデータ（Redux Toolkit で見た時など）
 *
 * を見た時には、両方のフィールドが存在することがあるため、混乱しないように注意。
 *
 * @param node
 * @returns
 */
export const convertToHide = (node: QuestionNode): ReportNodeHideSchema => {
  return node.question.isHidden ? { hide: true } : undefined
}
